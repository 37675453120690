$primary-color: #002645;
$primary-background-color: #002645;
$primary-background-color-75: rgba(0, 54, 100, 0.75);
$primary-background-color-50: rgba(0, 54, 100,0.5);
$primary-background-color-25: rgba(0, 54, 100,0.25);
$primary-background-color-light: #e6eaed;
$primary-background-color-dark: #002645;

$secondary-color: #003764;
$secondary-background-color: #003764;
$secondary-background-color-75: rgba(0, 38, 69,  0.75);
$secondary-background-color-50: rgba(0, 38, 69, 0.5);
$secondary-background-color-25: rgba(0, 38, 69, 0.35);
$secondary-background-color-light: #e6eaed;


$red-color: #e63744;
$red-background-color: #e63744;

$text-color: #002645;
$title-color: darken($text-color, 5%);
$h1-color: #20215c;

// Cookie
$mainCookieBG: #002645;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;
