.card__logo {
    display: none;
}

.detail-header-image {
	.countdown-wrapper {
		.card__logo {
			display: none;
		}
	}
}
section.detail-maps{
    .container{
        .maps-wrapper{
            &:before{
                background-color: $secondary-background-color-25;
            }
        }
    }
}
.btn:before {
    background: $red-background-color;
    background-color: $red-background-color;
}
header .logo {
    position: static;
}
header.high .logo .nvm,
header.narrow .logo .nvm  {
    display: none;
}
@media (min-width: 320px){
    .logo .mobile.logo-img {
        height: 80px;
    }
    header .phonenumber{
        display: block;
        left: auto;
        right: 60px;
        top: 15px;
        background-color: $primary-background-color-dark;
        color: #fff;
        padding: 15px 15px;
        a{
            color: #fff;
        }
        span{
            display: none;
        }
    }
}
@media (min-width: 992px){
    header .logo .logo-img {
        margin: 0 auto;
    }
    header.high .logo .nvm {
        top: 20px;
        left: 400px;
    }
    header.high .phonenumber {
        left: auto;
        right: 155px;
        top: 15px;
        background-color: $primary-background-color-dark;
        color: #fff;
        padding: 15px 30px;
        a{
            color: #fff;
        }
        span{
            display: inline-block;
        }
    }
    header.high .btn-login {
        top: 15px;
        right: 0px;
    }
    header.narrow .logo .nvm {
        top: 10px;
        left: 175px;
    }
    header.narrow .phonenumber {
        left: auto;
        right: 155px;
        top: 15px;
        background-color: $primary-background-color-dark;
        padding: 15px 30px;
        a{
            color: #fff;
        }
        span{
            display: inline-block;
        }
    }
    header.narrow .logo-img{
        height: 80px;
    }
}

/* fonts */
body,
.slider-content-left h1,
.filter-bar.page .content-title h1,
.detail-title-bar .content-title h1,
.breadcrumb h1,
.section.inhoud h1,
.section.inhoud h2,
.section.inhoud h3,
.content-title .breadcrumb,
.sold .verkocht,
.total,
.content-title,
.no,
.top-detail table th.table-title,
.top-detail table th,
h1,
h2,
h3,
h4,
h5,
h6,
span,
time,
input,
textarea,
a{
    font-family: Verdana, sans-serif;
}
@media (min-width: 1200px){
    .btn {
        font-size: 14px;
    }
}
/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #002645;
            }
            .footer-funda svg{
                fill: #002645;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #002645;
            }
            .footer-nrvg svg{
                fill: #002645;
            }
        }
    }
}
